import { Component } from '@angular/core';

@Component({
  selector: 'app-marketing-consultation',

  templateUrl: './marketing-consultation.component.html',
  styleUrl: './marketing-consultation.component.scss'
})
export class MarketingConsultationComponent {

}
