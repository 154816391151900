import { Component } from '@angular/core';

@Component({
  selector: 'app-social-media-channels',
  templateUrl: './social-media-channels.component.html',
  styleUrl: './social-media-channels.component.scss'
})
export class SocialMediaChannelsComponent {

}
