import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
import * as bootstrap from 'bootstrap';
declare var bootstrap: any;
interface Img {
    img: string;
}
@Component({
  selector: 'app-projects-one',
  templateUrl: './projects-one.component.html',
  styleUrls: ['./projects-one.component.scss']
})
export class ProjectsOneComponent implements OnInit {
  isHovered = false;

  clients: Img[] = [
      { img: "assets/img/protfolio/logos/1.png" },
      { img: "assets/img/protfolio/logos/2.png" },
      { img: "assets/img/protfolio/logos/3.png" },
      { img: "assets/img/protfolio/logos/4.png" },
      { img: "assets/img/protfolio/logos/5.png" },
      { img: "assets/img/protfolio/logos/6.png" },
      { img: "assets/img/protfolio/logos/7.png" },
      { img: "assets/img/protfolio/logos/8.png" },
      { img: "assets/img/protfolio/logos/9.png" },
      { img: "assets/img/protfolio/logos/10.png" },
      { img: "assets/img/protfolio/logos/11.png" },
      { img: "assets/img/protfolio/logos/12.png" },
      { img: "assets/img/protfolio/logos/13.png" },
      { img: "assets/img/protfolio/logos/14.png" },
      { img: "assets/img/protfolio/logos/15.png" },
      { img: "assets/img/protfolio/logos/16.png" },
      { img: "assets/img/protfolio/logos/17.png" },
      { img: "assets/img/protfolio/logos/18.png" },
      { img: "assets/img/protfolio/logos/19.png" },
      { img: "assets/img/protfolio/logos/20.png" },
      { img: "assets/img/protfolio/logos/21.png" },
      { img: "assets/img/protfolio/logos/22.png" },
      { img: "assets/img/protfolio/logos/23.png" },
      { img: "assets/img/protfolio/logos/24.png" },
      { img: "assets/img/protfolio/logos/25.png" },
      { img: "assets/img/protfolio/logos/26.png" },
      { img: "assets/img/protfolio/logos/27.png" },
      { img: "assets/img/protfolio/logos/28.png" },
      { img: "assets/img/protfolio/logos/29.png" },
      { img: "assets/img/protfolio/logos/30.png" },
      { img: "assets/img/protfolio/logos/31.png" },
      { img: "assets/img/protfolio/logos/32.png" },
      { img: "assets/img/protfolio/logos/33.png" },
      { img: "assets/img/protfolio/logos/34.png" },
      { img: "assets/img/protfolio/logos/35.png" },
      { img: "assets/img/protfolio/logos/36.png" },
      { img: "assets/img/protfolio/logos/37.png" },
      { img: "assets/img/protfolio/logos/38.png" },
      { img: "assets/img/protfolio/logos/39.png" },
      { img: "assets/img/protfolio/logos/40.png" },
      { img: "assets/img/protfolio/logos/41.png" },
      { img: "assets/img/protfolio/logos/42.png" },
      { img: "assets/img/protfolio/logos/43.png" },
      { img: "assets/img/protfolio/logos/44.png" },
      { img: "assets/img/protfolio/logos/45.png" },
      { img: "assets/img/protfolio/logos/46.png" },
      { img: "assets/img/protfolio/logos/47.png" },
      { img: "assets/img/protfolio/logos/48.png" },
      { img: "assets/img/protfolio/logos/49.png" },
      { img: "assets/img/protfolio/logos/50.png" },
      { img: "assets/img/protfolio/logos/51.png" },
      { img: "assets/img/protfolio/logos/52.png" },
  ];
  web: Img[] = [
      { img: "assets/img/projects/1.png" },
      { img: "assets/img/projects/2.png" },
      { img: "assets/img/projects/3.webp" },
      { img: "assets/img/projects/4.webp" },
      { img: "assets/img/projects/5.webp" },
      { img: "assets/img/projects/6.webp" },
  ];
  designs: Img[] = [
      { img: "assets/img/designing/14.jpg" },
      { img: "assets/img/designing/15.jpg" },
      { img: "assets/img/designing/16.jpg" },
      { img: "assets/img/designing/17.jpg" },
      { img: "assets/img/designing/18.jpg" },
      { img: "assets/img/designing/19.jpg" },
  ];
  projectImg: Img[] = [
      { img: "assets/img/protfolio/3.png" },
      { img: "assets/img/protfolio/5.png" },
      { img: "assets/img/protfolio/7.png" },
      { img: "assets/img/protfolio/9.png" },
      { img: "assets/img/protfolio/13.png" },
      { img: "assets/img/protfolio/14.png" },
      { img: "assets/img/protfolio/17.png" },
      { img: "assets/img/protfolio/20.png" },
      { img: "assets/img/protfolio/22.png" },
      { img: "assets/img/protfolio/23.png" },
      { img: "assets/img/protfolio/26.png" },
      { img: "assets/img/protfolio/27.png" },
      { img: "assets/img/protfolio/28.png" },
      { img: "assets/img/protfolio/29.png" },
      { img: "assets/img/protfolio/30.png" },
      { img: "assets/img/protfolio/31.png" },
      { img: "assets/img/protfolio/34.png" },
      { img: "assets/img/protfolio/35.png" },
      { img: "assets/img/protfolio/36.png" },
      { img: "assets/img/protfolio/37.png" },
      { img: "assets/img/protfolio/38.png" },
      { img: "assets/img/protfolio/40.png" },
      { img: "assets/img/protfolio/41.png" },
      { img: "assets/img/protfolio/42.png" },
      { img: "assets/img/protfolio/43.png" },
  ];

  constructor() {}
  selectedImage: string = '';

  openModal(image: string): void {
    console.log("Image passed:", image);
    this.selectedImage = image;
    console.log("Image passed:", image);
    const modalElement = document.getElementById(
        "imageModal"
    ) as HTMLElement;
    const modal = new bootstrap.Modal(modalElement);
    const modalDialog = modalElement.querySelector(
        ".modal-dialog"
    ) as HTMLElement | null;
    modal.show();
}
openModalDesigns(image: string): void {
    console.log("Image passed:", image);
    this.selectedImage = image;
    console.log("Image passed:", image);
    const modalElement = document.getElementById(
        "imageModalDesign"
    ) as HTMLElement;
    const modal = new bootstrap.Modal(modalElement);
    const modalDialog = modalElement.querySelector(
        ".modal-dialog"
    ) as HTMLElement | null;
    modal.show();
}
openModalWeb(image: string): void {
    console.log("Image passed:", image);
    this.selectedImage = image;
    console.log("Image passed:", image);
    const modalElement = document.getElementById(
        "imageModalWeb"
    ) as HTMLElement;
    const modal = new bootstrap.Modal(modalElement);
    const modalDialog = modalElement.querySelector(
        ".modal-dialog"
    ) as HTMLElement | null;
    modal.show();
}
  ngOnInit(): void {}
  customOptions: OwlOptions = {
    items: 4,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    rtl: true,
    dots: false,
    margin: 10,
    navSpeed: 500,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: [
        `<i class='bx fs-3 bx-chevron-right'></i>`,
        `<i class='bx fs-3 bx-chevron-left'></i>`,
    ],
    responsive: {
        0: {
            items: 2,
        },
        400: {
            items: 3,
        },
        740: {
            items: 4,
        },
        940: {
            items: 4,
        },
    },
    nav: true,
};
customOptionsLogos: OwlOptions = {
    items: 4,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    rtl: true,
    dots: false,
    margin: 10,
    navSpeed: 500,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: [
        `<i class='bx fs-3 bx-chevron-right'></i>`,
        `<i class='bx fs-3 bx-chevron-left'></i>`,
    ],
    responsive: {
        0: {
            items: 3,
        },
        400: {
            items: 3,
        },
        740: {
            items: 4,
        },
        940: {
            items: 8,
        },
    },
    nav: true,
};

}
