import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'bootstrap';
import { LanguageService } from 'src/app/language.service';


@Component({
  selector: 'app-navbar-one',
  templateUrl: './navbar-one.component.html',
  styleUrls: ['./navbar-one.component.scss']
})
export class NavbarOneComponent implements OnInit, AfterViewInit  {
  @ViewChild('languageDropdown') languageDropdown!: ElementRef;
  selectedLanguage = "ar";  
  constructor(private translate: TranslateService , private languageService :LanguageService) {
    translate.setDefaultLang('ar'); 
  }

  ngAfterViewInit(): void {
    const dropdownElement = this.languageDropdown?.nativeElement;
    if (dropdownElement) {
      new Dropdown(dropdownElement);
    }
  }

  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('selectedLanguage') || 'ar';
    this.selectedLanguage = storedLanguage;
    this.languageService.setLanguage(this.selectedLanguage); 
    this.translate.use(this.selectedLanguage).subscribe(() => {
      const direction = this.selectedLanguage === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction;
      document.documentElement.lang = this.selectedLanguage;
    });
  }

  toggleLanguage() {
    this.selectedLanguage = this.selectedLanguage === 'en' ? 'ar' : 'en';
    localStorage.setItem('selectedLanguage', this.selectedLanguage);
    this.languageService.setLanguage(this.selectedLanguage);
    this.translate.use(this.selectedLanguage).subscribe(() => {
      const direction = this.selectedLanguage === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction;
      document.documentElement.lang = this.selectedLanguage;
    });
  }
}
