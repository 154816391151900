import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
} from "@angular/forms";


@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    form: FormGroup = this.fb.group({
        from_name: ["", Validators.required],
        from_email: ["", Validators.required],
        subject: ["", Validators.required],
        message: ["", Validators.required],
    });
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {}
    send() {
        if (this.form.valid) {
            const phoneNumber = "+966559634398"; 
            const message = encodeURIComponent(
`Name: ${this.form.value.from_name}
Email: ${this.form.value.from_email}
Subject: ${this.form.value.subject}
Message: ${this.form.value.message}`
);

            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

            window.open(whatsappUrl, "_blank");

            alert("Message has been sent");
            this.form.reset();
        }
    }
}
