<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ "OUR_SERVICES.WD_TITLE_1" | translate}}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Great Design</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Optimal Choice</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Finest Quality</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Time Saving</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="learn-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="learn-content">
                    <h3>Features of Our Modern Web Solutions</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>
                    <ul class="learn-list">
                        <li><i class='bx bx-chevrons-right'></i> The Philosopy Of Business Analytics</li>
                        <li><i class='bx bx-chevrons-right'></i> Fast Track Your Business</li>
                        <li><i class='bx bx-chevrons-right'></i> Lies & Damn Lies About Your Business</li>
                        <li><i class='bx bx-chevrons-right'></i> The Ultimate Deal on Business</li>
                    </ul>
                    <div class="learn-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content mb-30">
                            <div class="icon">
                                <i class="flaticon-blog"></i>
                            </div>
                            <h3>Easy to Customize</h3>
                            <p>Tailor your site effortlessly, make updating content a breeze.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content mb-30">
                            <div class="icon bg-ba60fc">
                                <i class="flaticon-blueprint"></i>
                            </div>
                            <h3>Full Protection</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content">
                            <div class="icon bg-04cfc4">
                                <i class="flaticon-clock"></i>
                            </div>
                            <h3>High Speed</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content">
                            <div class="icon bg-f9b854">
                                <i class="flaticon-software"></i>
                            </div>
                            <h3>Design Branding</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="app-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <img src="assets/img/app.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="development-text">
                    <h3>Web & Mobile <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
                <div class="development-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>UI/UX Design</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-05dbcf">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-fec66f">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile Developement</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-66a6ff">
                        <i class="flaticon-devices"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="data-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="data-content">
                    <h3>Easy to Manage Your All Data by This App</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="data-inner-content">
                                <i class="flaticon-software"></i>
                                <h3>Modern Design</h3>
                                <p>Lorem ipsum dolor</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="data-inner-content bg-facf34">
                                <i class="flaticon-setting"></i>
                                <h3>Creative Idea</h3>
                                <p>Lorem ipsum dolor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="data-image">
                    <img src="assets/img/data.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                <span>{{
                    "OUR_SERVICES.TITLE_1_P2" | translate
                }}</span>
                {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
            </h2>
            <p>
                {{ "OUR_SERVICES.TITLE_2" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row g-4 justify-content-center align-items-stretch">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_2"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_2"
                                | translate
                        }}
                    </p>
                    <a routerLink="/e-commerce-development" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تصميم-المتاجر-الإلكترونية" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_3"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_3"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-cellphone"></i>                                            </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_4"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_4"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class='bx bx-link'></i>                                            </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_5"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_5"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_6"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_6"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class='bx bxl-wordpress'></i>
                    </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_7"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_7"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class='bx bx-search'></i>
                    </div>
                    <h3>
                        {{
                            "OUR_SERVICES.WD_TITLE_8"
                                | translate
                        }}
                    </h3>
                    <p>
                        {{
                            "OUR_SERVICES.WD_DESC_8"
                                | translate
                        }}
                    </p>
                    <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Latest <span>Projects</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="clients-section pt-100 pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>