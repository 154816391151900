<app-navbar-one></app-navbar-one>
<section class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_SERVICES.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_SERVICES.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab text-center">
                        <ul class="tabs">
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_1"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_2"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_3"
                                    | translate
                            }}</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/social-media-pages-management" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/إدارة-صفحات-السوشيال-ميديا" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/advertising-on-social-media-channels" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/الإعلان-على-قنوات-التواصل-الاجتماعي" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                class="flaticon-promotion"
                                            ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/google-paid-ads" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/إعلانات-جوجل-المدفوعة" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_5"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_5"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/request-a-marketing-consultation" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/طلب-استشارة-تسويقية" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-setting"></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/e-commerce-development" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تصميم-المتاجر-الإلكترونية" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-shopping-cart"></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-cellphone"></i>                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_4"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_4"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-link'></i>                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_5"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_5"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-envelope'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_6"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_6"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bxl-wordpress'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_7"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_7"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-search'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_8"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_8"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/search-engine-optimization-seo" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تهيئة-المواقع-لمحركات-البحث"  class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-palette'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DESIGN_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DESIGN_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/graphic-design" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تصميم-الجرافيك" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-palette'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DESIGN_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DESIGN_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                            <a routerLink="/branding" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                            <a routerLink="/تصميم-الهوية" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                                                "OUR_SERVICES.READ_MORE" | translate
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
