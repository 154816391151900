import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { HomeFiveComponent } from "./components/pages/home-five/home-five.component";
import { HomeSixComponent } from "./components/pages/home-six/home-six.component";
import { HomeSevenComponent } from "./components/pages/home-seven/home-seven.component";
import { HomeEightComponent } from "./components/pages/home-eight/home-eight.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { LogInComponent } from "./components/pages/log-in/log-in.component";
import { SignUpComponent } from "./components/pages/sign-up/sign-up.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { ProjectsTwoComponent } from "./components/pages/projects-two/projects-two.component";
import { ProjectsOneComponent } from "./components/pages/projects-one/projects-one.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { SocialMediaChannelsComponent } from "./components/pages/social-media-channels/social-media-channels.component";
import { SocialMediaPageManagementComponent } from "./components/pages/social-media-page-management/social-media-page-management.component";
import { GoogleAdsComponent } from "./components/pages/google-ads/google-ads.component";
import { MarketingConsultationComponent } from "./components/pages/marketing-consultation/marketing-consultation.component";
import { WebDevelopmentComponent } from "./components/pages/web-development/web-development.component";
import { ECommerceComponent } from "./components/pages/e-commerce/e-commerce.component";
import { SEOComponent } from "./components/pages/seo/seo.component";
import { GraphicAndIdentityDesignComponent } from "./components/pages/graphic-and-identity-design/graphic-and-identity-design.component";
import { BrandingComponent } from './components/pages/branding/branding.component';
import { GraphicDesignComponent } from './components/pages/graphic-design/graphic-design.component';


export const routes: Routes = [
    { path: "", component: HomeOneComponent },
    { path: "من-نحن", component: AboutComponent },
    { path: "خدماتنا", component: ServicesOneComponent },
    { path: "فريق-العمل", component: TeamComponent },
    { path: "اعمالنا", component: ProjectsOneComponent },
    { path: "اتصل-بنا", component: ContactComponent },
    { path: "إدارة-صفحات-السوشيال-ميديا", component: SocialMediaPageManagementComponent },
    { path: "الإعلان-على-قنوات-التواصل-الاجتماعي", component: SocialMediaChannelsComponent },
    { path: "إعلانات-جوجل-المدفوعة", component: GoogleAdsComponent },
    { path: "طلب-استشارة-تسويقية", component: MarketingConsultationComponent },
    { path: "تصميم-المواقع-الإلكترونية", component: WebDevelopmentComponent },
    { path: "تصميم-المتاجر-الإلكترونية", component: ECommerceComponent },
    { path: "تهيئة-المواقع-لمحركات-البحث", component: SEOComponent },
    { path: "تصميم-الجرافيك", component: GraphicDesignComponent },
    { path: "تصميم-الهوية", component: BrandingComponent },


    { path: "about", component: AboutComponent },
    { path: "services", component: ServicesOneComponent },
    { path: "portfolio", component: ProjectsOneComponent },
    { path: "team", component: TeamComponent },
    { path: "contact", component: ContactComponent },
    { path: "social-media-pages-management", component: SocialMediaPageManagementComponent },
    { path: "advertising-on-social-media-channels", component: SocialMediaChannelsComponent },
    { path: "google-paid-ads", component: GoogleAdsComponent },
    { path: "request-a-marketing-consultation", component: MarketingConsultationComponent },
    { path: "web-development-&-design", component: WebDevelopmentComponent },
    { path: "e-commerce-development", component: ECommerceComponent },
    { path: "search-engine-optimization-seo", component: SEOComponent },
    { path: "graphic-design", component: GraphicDesignComponent },
    { path: "branding", component: BrandingComponent },
    { path: "services-two", component: ServicesTwoComponent },
    { path: "services-details", component: ServicesDetailsComponent },
    { path: "projects-two", component: ProjectsTwoComponent },
    { path: "projects-details", component: ProjectsDetailsComponent },
    { path: "home-tow", component: HomeTwoComponent },
    { path: "home-three", component: HomeThreeComponent },
    { path: "home-four", component: HomeFourComponent },
    { path: "home-five", component: HomeFiveComponent },
    { path: "home-six", component: HomeSixComponent },
    { path: "home-seven", component: HomeSevenComponent },
    { path: "home-eight", component: HomeEightComponent },
    { path: "faq", component: FaqComponent },
    { path: "log-in", component: LogInComponent },
    { path: "sign-up", component: SignUpComponent },
    { path: "error", component: ErrorComponent },

    { path: "**", component: ErrorComponent }, 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
