import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageSource = new BehaviorSubject<string>('ar'); // default language is Arabic
  currentLanguage$ = this.languageSource.asObservable();

  setLanguage(language: string): void {
    this.languageSource.next(language);
  }
}
