<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" routerLink="/">
                <img class="logoo" src="assets/img/logo.png" alt="logo" />
            </a>

            <div class="collapse navbar-collapse" id="en-routers"
                [ngClass]="{ 'hide-element': selectedLanguage === 'ar' }">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">{{ "NAV.HOME" | translate }}
                        </a>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ "NAV.ABOUT_US" | translate }}</a>
                    </li>


                    <li class="nav-item"><a routerLink="/services" class="nav-link">{{
                            "NAV.OUR_SERVICES" | translate
                            }} <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/social-media-pages-management" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                                    "OUR_SERVICES.DM_TITLE_1" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/advertising-on-social-media-channels" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                                    "OUR_SERVICES.DM_TITLE_2" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/google-paid-ads" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DM_TITLE_3" | translate
                                    }}</a>
                            </li>



                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/request-a-marketing-consultation" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                                    "OUR_SERVICES.DM_TITLE_5" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/web-development-&-design" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.WD_TITLE_1" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/graphic-design" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DESIGN_TITLE_1" |
                                    translate
                                    }}</a>
                            </li>
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/branding" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DESIGN_TITLE_2" |
                                    translate
                                    }}</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/portfolio" class="nav-link">{{ "NAV.PROJECTS" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/team" class="nav-link">{{ "NAV.TEAM" | translate }}
                        </a>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ "NAV.CONTACT" | translate }}</a>
                    </li>
                </ul>
            </div>
            <div class="collapse navbar-collapse" id="ar-routers"
                [ngClass]="{ 'hide-element': selectedLanguage === 'en' }">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">{{ "NAV.HOME" | translate }}
                        </a>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/من-نحن" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ "NAV.ABOUT_US" | translate }}</a>
                    </li>
                    <li class="nav-item"><a routerLink="/خدماتنا" class="nav-link">{{
                            "NAV.OUR_SERVICES" | translate
                            }} <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/إدارة-صفحات-السوشيال-ميديا" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DM_TITLE_1" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/الإعلان-على-قنوات-التواصل-الاجتماعي" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                                    "OUR_SERVICES.DM_TITLE_2" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/إعلانات-جوجل-المدفوعة" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DM_TITLE_3" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/طلب-استشارة-تسويقية" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DM_TITLE_5" | translate
                                    }}</a>
                            </li>

                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/تصميم-المواقع-الإلكترونية" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.WD_TITLE_1" | translate
                                    }}</a>
                            </li>
                            
       
                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/تصميم-الجرافيك" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DESIGN_TITLE_1" |
                                    translate
                                    }}</a>
                            </li>
                            <li class="nav-item text-end" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/تصميم-الهوية" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ "OUR_SERVICES.DESIGN_TITLE_2" |
                                    translate
                                    }}</a>
                            </li>
                        </ul>
                    </li>


                    <li class="nav-item">
                        <a routerLink="/اعمالنا" class="nav-link">{{ "NAV.PROJECTS" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/فريق-العمل" class="nav-link">{{ "NAV.TEAM" | translate }}
                        </a>
                    </li>

                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/اتصل-بنا" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ "NAV.CONTACT" | translate }}</a>
                    </li>
                </ul>
            </div>
            <div class="others-options text-center">
                <div>
                    <button class="btn p-0" (click)="toggleLanguage()" type="button">
                        <img *ngIf="selectedLanguage === 'en'" src="assets/flags/ar.svg" alt="English"
                            style="width: 24px; height: 16px" />
                        <img *ngIf="selectedLanguage === 'ar'" src="assets/flags/en.svg" alt="Arabic"
                            style="width: 24px; height: 16px" />
                    </button>
                </div>
            </div>
        </nav>
    </div>
</div>