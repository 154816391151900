<app-navbar-one></app-navbar-one>

<div class="section-title mt-5">
    <h2>
        Digital <span>Marketing</span>
    </h2>
    <p>{{ "our_clints.desc" | translate }}</p>
    <div class="bar"></div>
</div>
<section class="services-details-area">
    <div class="container">
        <!-- <div class="services-details-video">
            <div class="details-image">
                <img src="assets/img/services-details/bg.jpg" alt="image">
            </div>
            <div class="details-video">
                <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
            </div>
            <div class="text">
                <h3>We Provide Useful Services</h3>
                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div> -->
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Exceptional Best Products</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour, or randomised words which don't look
                            even slightly believable.</p>
                        <div class="features-text">
                            <h4>Reasons to Go with vegetables</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's.</p>
                        </div>
                        <div class="features-text">
                            <h4>Summer Vegetables Fruits Collection</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/img1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/img2.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>We Provide Useful Services</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's.</p>
                        <div class="services-details-accordion">
                            <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne"
                                            aria-expanded="false" aria-controls="collapseOne">What access do I have on
                                            the free plan? <i class='bx bx-chevron-down'></i></a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" role="tabpanel"
                                        data-bs-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                            href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What
                                            access do I have on a free trial? <i class='bx bx-chevron-down'></i></a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" role="tabpanel"
                                        data-bs-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingThree">
                                        <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                            href="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">Does the price go up as my team gets larger?
                                            <i class='bx bx-chevron-down'></i></a>
                                    </div>
                                    <div id="collapseThree" class="collapse" role="tabpanel"
                                        data-bs-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingFour">
                                        <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                            href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How
                                            can I cancel my subscription? <i class='bx bx-chevron-down'></i></a>
                                    </div>
                                    <div id="collapseFour" class="collapse" role="tabpanel"
                                        data-bs-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>