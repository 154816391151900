<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ "OUR_SERVICES.DM_TITLE_2" | translate}}</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="project-details-image">
                    <img src="assets/img/services-details/social-media-channels/2.png" alt="projects">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="project-details-image">
                    <img src="assets/img/services-details/social-media-channels/3.png" alt="projects">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h3>{{ "SocialMediaChannels.P1" | translate}}</h3>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> {{ "SocialMediaChannels.P2" | translate}}</h4>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> {{ "SocialMediaChannels.P3" | translate}}</h4>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> {{ "SocialMediaChannels.P4" | translate}}</h4>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> {{ "SocialMediaChannels.P5" | translate}}</h4>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> {{ "SocialMediaChannels.P6" | translate}}</h4>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
