import { Component } from '@angular/core';
import { LanguageService } from "src/app/language.service";

@Component({
  selector: 'app-social-media-page-management',
  templateUrl: './social-media-page-management.component.html',
  styleUrl: './social-media-page-management.component.scss'
})
export class SocialMediaPageManagementComponent {
  currentLanguage = 'ar';
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.currentLanguage$.subscribe(language => {
        this.currentLanguage = language;
});
}
}
