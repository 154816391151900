<app-navbar-one></app-navbar-one>

<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/about.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        {{ "Audience.Audience_Title_1" | translate }}
                        <span>{{
                            "Audience.Audience_Title_2" | translate
                        }}</span>
                        {{ "Audience.Audience_Title_3" | translate }}
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Audience.Audience_Desc_1" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_2" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_3" | translate }}
                    </p>
                    <div class="audience-btn">
                        <a routerLink="/contact" class="default-btn">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "team.team_Title_1" | translate }}
                <span>{{ "team.team_Title_2" | translate }}</span>
                {{ "team.team_Title_3" | translate }}
            </h2>
            <p>
                {{ "team.team_desc" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/Amr-Ragaee.webp" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_1" | translate }}</h3>
                        <span>{{ "team.member_jop_title_1" | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 	">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/Ahmed-Gamal.webp" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_2" | translate }}</h3>
                        <span>{{ "team.member_jop_title_2" | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4 ">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Malak-El-Sherif.webp"  alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_3" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_3" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Habiba-Tarek.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_4" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_4" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Hossam-Benhawy.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_5" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_5" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mamdoh-Bakry.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_6" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_6" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Omnia-Hassan.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_7" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_7" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mona-Omar.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_8" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_8" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4 ">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Israa-Gomaa.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_9" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_9" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Israa-Adel.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_10" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_10" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Ali-Ezzat.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_11" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_11" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mahmoud-Arafa.jpeg" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_12" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_12" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Habiba-Mohamed.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_13" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_13" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Marwa-Mohamed.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_14" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_14" | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
