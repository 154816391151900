<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ "OUR_SERVICES.DM_TITLE_1" | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="project-details-image">
                    <img
                        src="assets/img/services-details/Social-Media-Management/2.png"
                        alt="projects"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="project-details-image">
                    <img
                        src="assets/img/services-details/Social-Media-Management/3.png"
                        alt="projects"
                    />
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h3>{{ "SocialMediaManagement.P1" | translate }}</h3>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P2" | translate }}
                        </h4>
                    </div>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P3" | translate }}
                        </h4>
                    </div>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P4" | translate }}
                        </h4>
                    </div>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P5" | translate }}
                        </h4>
                    </div>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P6" | translate }}
                        </h4>
                    </div>
                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i>
                            {{ "SocialMediaManagement.P7" | translate }}
                        </h4>
                    </div>

                    <h3>{{ "SocialMediaManagement.P8" | translate }}</h3>
                </div>
            </div>
        </div>
    </div>
</section>
