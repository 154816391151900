import { Component } from '@angular/core';

@Component({
  selector: 'app-google-ads',
  templateUrl: './google-ads.component.html',
  styleUrl: './google-ads.component.scss'
})
export class GoogleAdsComponent {

}
