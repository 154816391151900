<app-navbar-one></app-navbar-one>
<div class="main-banner-area-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center grid gap-0 row-gap-md-5">
                    <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                        <div class="main-banner-content pe-md-3">
                            <span>
                                {{ "HEADDER.TITLE" | translate }}
                            </span>
                            <h1>
                                {{ "HEADDER.TITLE_2" | translate }}
                            </h1>
                            <p>{{ "HEADDER.DESC" | translate }}</p>

                            <div class="banner-btn">
                                <a
                                [ngClass]="{ 'hide-element': currentLanguage === 'ar' }"
                                    routerLink="/services"
                                    class="default-btn"
                                    >{{ "HEADDER.GET_START" | translate }}</a
                                >
                                <a
                                [ngClass]="{ 'hide-element': currentLanguage === 'en' }"
                                    routerLink="/خدماتنا"
                                    class="default-btn"
                                    >{{ "HEADDER.GET_START" | translate }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                        <div class="banner-image">
                            <img
                                src="assets/img/home-four/img1.png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</div>
<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                <span>{{ "OUR_SERVICES.TITLE_1_P2" | translate }}</span>
                {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
            </h2>
            <p>
                {{ "OUR_SERVICES.TITLE_2" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row g-4 align-items-stretch">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_1" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_1" | translate }}
                    </p>
                    <a routerLink="/services" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/خدماتنا" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_2" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_2" | translate }}
                    </p>
                    <a routerLink="/services" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/خدماتنا" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="bx bx-palette"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_3" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_3" | translate }}
                    </p>
                    <a routerLink="/services" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                    <a routerLink="/خدماتنا" class="read-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="audience-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/about.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        {{ "Audience.Audience_Title_1" | translate }}
                        <span>{{
                            "Audience.Audience_Title_2" | translate
                        }}</span>
                        {{ "Audience.Audience_Title_3" | translate }}
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Audience.Audience_Desc_1" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_2" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_3" | translate }}
                    </p>
                    <div class="audience-btn">
                        <a routerLink="/contact" class="default-btn" [ngClass]="{ 'hide-element': currentLanguage === 'ar' }">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                        <a routerLink="/اتصل-بنا" class="default-btn" [ngClass]="{ 'hide-element': currentLanguage === 'en' }">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>
                            {{ "Solutions.Solutions_Title_1" | translate }}
                            <span>{{
                                "Solutions.Solutions_Title_2" | translate
                            }}</span>
                            {{ "Solutions.Solutions_Title_3" | translate }}
                        </h3>
                        <div class="bar"></div>
                        <p>
                            {{ "Solutions.Solutions_Desc" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_1" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_1" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-analysis"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_2" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_2" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_3" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_3" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_4" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_4" | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solutions-image">
                    <img src="assets/img/solutions.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="design-image">
                    <img src="assets/img/design.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="design-content">
                    <h3>
                        {{ "Development.Development_Title_1" | translate }}
                        <span>{{
                            "Development.Development_Title_2" | translate
                        }}</span>
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Development.Development_Desc" | translate }}
                    </p>
                    <ul class="design-list">
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_1" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_2" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_3" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_4" | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "counter.counter_Title_1" | translate }}
                <span>{{ "counter.counter_Title_2" | translate }}</span>
                {{ "counter.counter_Title_3" | translate }}
                <span>{{ "counter.counter_Title_4" | translate }}</span>
            </h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="2132">00</span></h3>
                    <p>{{ "counter.counter_desc_1" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="982">00</span></h3>
                    <p>{{ "counter.counter_desc_2" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="736">00</span></h3>
                    <p>{{ "counter.counter_desc_3" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="359">00</span></h3>
                    <p>{{ "counter.counter_desc_4" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "team.team_Title_1" | translate }}
                <span>{{ "team.team_Title_2" | translate }}</span>
                {{ "team.team_Title_3" | translate }}
            </h2>
            <p>
                {{ "team.team_desc" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/Amr-Ragaee.webp" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_1" | translate }}</h3>
                        <span>{{ "team.member_jop_title_1" | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 	">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/Ahmed-Gamal.webp" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_2" | translate }}</h3>
                        <span>{{ "team.member_jop_title_2" | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4 ">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Malak-El-Sherif.webp"  alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_3" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_3" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Habiba-Tarek.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_4" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_4" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Hossam-Benhawy.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_5" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_5" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mamdoh-Bakry.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_6" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_6" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Omnia-Hassan.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_7" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_7" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mona-Omar.webp" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_8" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_8" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4 ">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Israa-Gomaa.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_9" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_9" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Israa-Adel.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_10" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_10" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Ali-Ezzat.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_11" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_11" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Mahmoud-Arafa.jpeg" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_12" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_12" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Habiba-Mohamed.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_13" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_13" | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img src="assets/img/team/Marwa-Mohamed.png" alt="image" />
                            </div>
                            <div class="content">
                                <h3 class="member_name">{{ "team.member_name_14" | translate }}</h3>
                                <span class="member_job-name">{{ "team.member_jop_title_14" | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_1"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_2"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_3"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_4"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="mb-5 text-center">
                                    <h4 class="mb-5">
                                        {{
                                            "OUR_PORTFOLIO.LAST_PROJECT"
                                                | translate
                                        }}
                                    </h4>
                                    <owl-carousel-o [options]="customOptions">
                                        <ng-template
                                            carouselSlide
                                            *ngFor="
                                                let projectImg of projectImg
                                            "
                                            ><img
                                                [src]="projectImg.img"
                                                loading="lazy"
                                                alt=""
                                                (click)="
                                                    openModal(projectImg.img)
                                                "
                                            />
                                        </ng-template>
                                    </owl-carousel-o>
                                    <div
                                        class="modal fade p-3"
                                        id="imageModal"
                                        tabindex="-1"
                                        aria-labelledby="imageModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div
                                            class="modal-dialog modal-dialog-project p-0 modal-dialog-centered"
                                        >
                                            <div class="modal-content p-0">
                                                <button
                                                    type="button"
                                                    class="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <i class="bx bx-x"></i>
                                                </button>
                                                <div class="modal-body">
                                                    <img
                                                        [src]="selectedImage"
                                                        class="img-fluid"
                                                        alt="Image Preview"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-5 text-center">
                                    <owl-carousel-o
                                        [options]="customOptionsLogos"
                                    >
                                        <ng-template
                                            carouselSlide
                                            *ngFor="let clients of clients"
                                            ><img [src]="clients.img" alt="" />
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4">
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6"
                                        *ngFor="let web of web"
                                    >
                                        <div
                                            class="single-protfolio-box single-protfolio-box-2"
                                        >
                                            <div class="image">
                                                <img
                                                    [src]="web.img"
                                                    alt="image"
                                                    (click)="
                                                        openModalWeb(web.img)
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="modal fade p-3"
                                    id="imageModalWeb"
                                    tabindex="-1"
                                    aria-labelledby="imageModalLabel"
                                    aria-hidden="true"
                                >
                                    <div
                                        class="modal-dialog modal-dialog-des p-0 modal-dialog-centered"
                                    >
                                        <div class="modal-content p-0">
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <i class="bx bx-x"></i>
                                            </button>
                                            <div class="modal-body">
                                                <img
                                                    [src]="selectedImage"
                                                    class="img-fluid w-100"
                                                    alt="Image Preview"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4">
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6"
                                        *ngFor="let designs of designs"
                                    >
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <img
                                                    [src]="designs.img"
                                                    alt="image"
                                                    (click)="
                                                        openModalDesigns(
                                                            designs.img
                                                        )
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal fade"
                                        id="imageModalDesign"
                                        tabindex="-1"
                                        aria-labelledby="imageModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div
                                            class="modal-dialog modal-dialog-des p-0 modal-dialog-centered"
                                        >
                                            <div class="modal-content p-0">
                                                <button
                                                    type="button"
                                                    class="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <i class="bx bx-x"></i>
                                                </button>
                                                <div class="modal-body">
                                                    <img
                                                        [src]="selectedImage"
                                                        class="img-fluid"
                                                        alt="Image Preview"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div
                                    class="row g-4 justify-content-center align-items-stretch"
                                >
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    اطلالتكِ في اليوم الوطني
                                                    لازمها قطعه بلون وطننا
                                                </p>
                                                <p class="management-text">
                                                    ومن العماري هتلاقيها بسعر  بأقل سعر في المملكة 🔥
                                                </p>
                                                <p class="management-text">
                                                    سارعي الحين في اختيار قطعتكِ
                                                    المفضلة من العماري للذهب
                                                    والمجوهرات
                                                </p>
                                                <p class="management-text">
                                                    #اليوم_الوطني #نحلم_ونحقق
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ومجوهرات العماري بتفاصيلها اللي تنبض بالفخامة والرقي تناسب كل مناسباتك✨
                                                </p>
                                                <p class="management-text">
                                                    ومجوهرات العماري بتفاصيلها
                                                    اللي تنبض بالفخامة والرقي
                                                    تناسب كل مناسباتك
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    لأننا نؤمن أن سر جمال المرأه
                                                    في تميزها
                                                </p>
                                                <p class="management-text">
                                                    العماري للذهب والمچوهرات يصدر Limited Edition✋ 

                                                </p>
                                                <p class="management-text">
                                                    صممت هذه الأطقم المختارة لتكون فقط لكي أنتي 
                                                    لتكوني مميزه دائماً بجمالك 🤩
                                                </p>
                                                <p class="management-text">
                                                    طقم واحد فقط مُتاح من كل
                                                    تصميم لعشاق التفرد والتميز
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ربح مع العماري 😍🎉
                                                </p>
                                                <p class="management-text">
                                                    الآن بمناسبة اليوم الوطني
                                                    السعودي متجر العماري عمل على
                                                    اي طلب سحب على جوائز قيمة
                                                </p>
                                                <p class="management-text">
                                                    أطلب الآن من متجر العماري وادخل السحب بأسرع وقت 🤩👌🏻

                                                </p>
                                                <p class="management-text">
                                                    #اليومالوطني
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ------------------------------------------------ -->
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    Only one solution restores
                                                    the brightness of your face
                                                    💡
                                                </p>
                                                <p class="management-text-en">
                                                    The new vitamin C serum from
                                                    Valdivia Beauty 💛
                                                </p>
                                                <p class="management-text-en">
                                                    To lighten and unify skin
                                                    tone ✔️
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    To achieve the perfect
                                                    bronzy look and have amazing
                                                    skin during a fun summer 👌🏻
                                                </p>
                                                <p class="management-text-en">
                                                    You have to spray bronze oil
                                                    from Valdivia beauty ☀️
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    We can maintain the
                                                    appearance of our skin in
                                                    simple ways 💙
                                                </p>
                                                <p class="management-text-en">
                                                    Valdivia Beauty Anti-Blemish
                                                    Serum
                                                </p>
                                                <p class="management-text-en">
                                                    ✅ Maintain a uniform skin
                                                    tone
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    Direct sunlight is one of
                                                    the first causes of spots
                                                    and hyperpigmentation 🌞
                                                </p>
                                                <p class="management-text-en">
                                                    Valdivia beauty sun
                                                    protection set ✋🏻
                                                </p>
                                                <p class="management-text-en">
                                                    📌 With three levels of
                                                    protection
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ////////////////////////////////////// -->
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    قربهم من بعض ومتشلش هم الكاش
                                                </p>
                                                <p class="management-text">
                                                    رجع ضحكتك المثالية من غير
                                                    مسافات في عيادة STM وركب
                                                    التقويم بالتقسيط المريح 😁
                                                </p>
                                                <p class="management-text">
                                                    متاح جميع جميع انواع التقويم
                                                    معدني وشفاف 👌
                                                </p>
                                                <p class="management-text">
                                                    اسأل علي الخصم والتقسيط وجمع
                                                    شمل سنانك المتفرقه من سنين
                                                    😌
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ابتسامتك في العيد هتبقي احلي
                                                    بجلسة تبييض في عيادة STM ✋
                                                </p>
                                                <p class="management-text">
                                                    عياده STM تتتمني لكم عيد
                                                    أضحي سعيد ❤️
                                                </p>
                                                <p class="management-text">
                                                    الحقي عروض العيد لفتره
                                                    محدوده
                                                </p>
                                                <p class="management-text">
                                                    اسألينا و هنبعتلك التفاصيل
                                                    😍
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    حاسيين بوجعك 🦷
                                                </p>
                                                <p class="management-text">
                                                    لأن ضروس العقل المدفونة مش
                                                    بس وجعها صعب
                                                </p>
                                                <p class="management-text">
                                                    ده كمان معرضه اكتر للإصابة
                                                    بالأمراض وتسوس الأسنان 🥹
                                                </p>
                                                <p class="management-text">
                                                    🆕 لفتره محدوده في عيادة STM
                                                    خلع ضرس العقل المدفون ب ٨٠٠
                                                    جنيه فقط 🆕
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ده مش كشاف نور دي اسنانك
                                                    اللي هتنور بعد اول جلسة
                                                    تبييض في عيادة STM 😁
                                                </p>
                                                <p class="management-text">
                                                    خصم مش هيتكرررر تاني 💣
                                                </p>
                                                <p class="management-text">
                                                    لو خايفه من شكل ضحتك في
                                                    الفرح أو عايزه اسنانك منوره
                                                    يبقي مفيش قدامك غير انك
                                                    تلحقي العرض🏃
                                                </p>
                                                <p class="management-text">
                                                    احجزي دلوقتي ومتخبيش ضحتك في
                                                    أي مناسبة 
                                                </p>
                                                <p class="management-text">
                                                    متنسيش تسالي علي الخصم والتقسيط   💌
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="clients-section pt-100 pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>
<div
    class="modal fade"
    [id]="'imageModal-' + selectedImage"
    tabindex="-1"
    aria-labelledby="imageModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog p-0 modal-dialog-centered">
        <div class="modal-content p-0">
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            >
                <i class="bx bx-x"></i>
            </button>
            <div class="modal-body">
                <img
                    [src]="selectedImage"
                    class="img-fluid"
                    alt="Image Preview"
                />
            </div>
        </div>
    </div>
</div>
