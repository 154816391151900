import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { HomeFiveComponent } from "./components/pages/home-five/home-five.component";
import { HomeSixComponent } from "./components/pages/home-six/home-six.component";
import { HomeSevenComponent } from "./components/pages/home-seven/home-seven.component";
import { HomeEightComponent } from "./components/pages/home-eight/home-eight.component";
import { NavbarOneComponent } from "./components/layouts/navbar-one/navbar-one.component";
import { NavbarTwoComponent } from "./components/layouts/navbar-two/navbar-two.component";
import { NavbarThreeComponent } from "./components/layouts/navbar-three/navbar-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ProjectsOneComponent } from "./components/pages/projects-one/projects-one.component";
import { ProjectsTwoComponent } from "./components/pages/projects-two/projects-two.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LogInComponent } from "./components/pages/log-in/log-in.component";
import { SignUpComponent } from "./components/pages/sign-up/sign-up.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import {
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ReactiveFormsModule } from "@angular/forms";
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth"; // Import if using Firebase Auth
import { AngularFirestoreModule } from "@angular/fire/compat/firestore"; // Import if u
import { environment } from "../environments/environment";
import { firebaseConfig } from "../environments/firebase-config";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SocialMediaPageManagementComponent } from "./components/pages/social-media-page-management/social-media-page-management.component";
import { SocialMediaChannelsComponent } from "./components/pages/social-media-channels/social-media-channels.component";
import { GoogleAdsComponent } from "./components/pages/google-ads/google-ads.component";
import { MarketingConsultationComponent } from "./components/pages/marketing-consultation/marketing-consultation.component";
import { WebDevelopmentComponent } from "./components/pages/web-development/web-development.component";
import { ECommerceComponent } from "./components/pages/e-commerce/e-commerce.component";
import { SEOComponent } from "./components/pages/seo/seo.component";
import { GraphicAndIdentityDesignComponent } from "./components/pages/graphic-and-identity-design/graphic-and-identity-design.component";
import { BrandingComponent } from './components/pages/branding/branding.component';
import { GraphicDesignComponent } from './components/pages/graphic-design/graphic-design.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        NavbarOneComponent,
        NavbarTwoComponent,
        NavbarThreeComponent,
        AboutComponent,
        ServicesOneComponent,
        ServicesTwoComponent,
        ServicesDetailsComponent,
        ProjectsOneComponent,
        ProjectsTwoComponent,
        ProjectsDetailsComponent,
        ContactComponent,
        BlogGridComponent,
        BlogRightSidebarComponent,
        BlogDetailsComponent,
        PricingComponent,
        TeamComponent,
        FaqComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ErrorComponent,
        LogInComponent,
        SignUpComponent,
        SocialMediaPageManagementComponent,
        SocialMediaChannelsComponent,
        GoogleAdsComponent,
        MarketingConsultationComponent,
        WebDevelopmentComponent,
        ECommerceComponent,
        SEOComponent,
        GraphicAndIdentityDesignComponent,
        ComingSoonComponent,
        BrandingComponent,
        GraphicDesignComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        AngularFireModule.initializeApp(firebaseConfig),
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CarouselModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
