<app-navbar-one></app-navbar-one>


<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_1"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_2"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_3"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_4"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="mb-5 text-center">
                                    <h4 class="mb-5">
                                        {{
                                            "OUR_PORTFOLIO.LAST_PROJECT"
                                                | translate
                                        }}
                                    </h4>
                                    <owl-carousel-o [options]="customOptions">
                                        <ng-template
                                            carouselSlide
                                            *ngFor="
                                                let projectImg of projectImg
                                            "
                                            ><img
                                                [src]="projectImg.img"
                                                alt=""
                                                (click)="
                                                    openModal(projectImg.img)
                                                "
                                            />
                                        </ng-template>
                                    </owl-carousel-o>
                                    <div
                                        class="modal fade p-3"
                                        id="imageModal"
                                        tabindex="-1"
                                        aria-labelledby="imageModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div
                                            class="modal-dialog modal-dialog-project p-0 modal-dialog-centered"
                                        >
                                            <div class="modal-content p-0">
                                                <button
                                                    type="button"
                                                    class="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <i class="bx bx-x"></i>
                                                </button>
                                                <div class="modal-body">
                                                    <img
                                                        [src]="selectedImage"
                                                        class="img-fluid"
                                                        alt="Image Preview"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-5 text-center">
                                    <owl-carousel-o
                                        [options]="customOptionsLogos"
                                    >
                                        <ng-template
                                            carouselSlide
                                            *ngFor="let clients of clients"
                                            ><img [src]="clients.img" alt="" />
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4">
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6"
                                        *ngFor="let web of web"
                                    >
                                        <div
                                            class="single-protfolio-box single-protfolio-box-2"
                                        >
                                            <div class="image">
                                                <img
                                                    [src]="web.img"
                                                    alt="image"
                                                    (click)="
                                                        openModalWeb(web.img)
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="modal fade p-3"
                                    id="imageModalWeb"
                                    tabindex="-1"
                                    aria-labelledby="imageModalLabel"
                                    aria-hidden="true"
                                >
                                    <div
                                        class="modal-dialog modal-dialog-des p-0 modal-dialog-centered"
                                    >
                                        <div class="modal-content p-0">
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <i class="bx bx-x"></i>
                                            </button>
                                            <div class="modal-body">
                                                <img
                                                    [src]="selectedImage"
                                                    class="img-fluid w-100"
                                                    alt="Image Preview"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4">
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6"
                                        *ngFor="let designs of designs"
                                    >
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <img
                                                    [src]="designs.img"
                                                    alt="image"
                                                    (click)="
                                                        openModalDesigns(
                                                            designs.img
                                                        )
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal fade"
                                        id="imageModalDesign"
                                        tabindex="-1"
                                        aria-labelledby="imageModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div
                                            class="modal-dialog modal-dialog-des p-0 modal-dialog-centered"
                                        >
                                            <div class="modal-content p-0">
                                                <button
                                                    type="button"
                                                    class="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <i class="bx bx-x"></i>
                                                </button>
                                                <div class="modal-body">
                                                    <img
                                                        [src]="selectedImage"
                                                        class="img-fluid"
                                                        alt="Image Preview"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div
                                    class="row g-4 justify-content-center align-items-stretch"
                                >
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    اطلالتكِ في اليوم الوطني
                                                    لازمها قطعه بلون وطننا
                                                </p>
                                                <p class="management-text">
                                                    ومن العماري هتلاقيها بسعر  بأقل سعر في المملكة 🔥
                                                </p>
                                                <p class="management-text">
                                                    سارعي الحين في اختيار قطعتكِ
                                                    المفضلة من العماري للذهب
                                                    والمجوهرات
                                                </p>
                                                <p class="management-text">
                                                    #اليوم_الوطني #نحلم_ونحقق
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ومجوهرات العماري بتفاصيلها اللي تنبض بالفخامة والرقي تناسب كل مناسباتك✨
                                                </p>
                                                <p class="management-text">
                                                    ومجوهرات العماري بتفاصيلها
                                                    اللي تنبض بالفخامة والرقي
                                                    تناسب كل مناسباتك
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    لأننا نؤمن أن سر جمال المرأه
                                                    في تميزها
                                                </p>
                                                <p class="management-text">
                                                    العماري للذهب والمچوهرات يصدر Limited Edition✋ 

                                                </p>
                                                <p class="management-text">
                                                    صممت هذه الأطقم المختارة لتكون فقط لكي أنتي 
                                                    لتكوني مميزه دائماً بجمالك 🤩
                                                </p>
                                                <p class="management-text">
                                                    طقم واحد فقط مُتاح من كل
                                                    تصميم لعشاق التفرد والتميز
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/gold-4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ربح مع العماري 😍🎉
                                                </p>
                                                <p class="management-text">
                                                    الآن بمناسبة اليوم الوطني
                                                    السعودي متجر العماري عمل على
                                                    اي طلب سحب على جوائز قيمة
                                                </p>
                                                <p class="management-text">
                                                    أطلب الآن من متجر العماري وادخل السحب بأسرع وقت 🤩👌🏻

                                                </p>
                                                <p class="management-text">
                                                    #اليومالوطني
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ------------------------------------------------ -->
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    Only one solution restores
                                                    the brightness of your face
                                                    💡
                                                </p>
                                                <p class="management-text-en">
                                                    The new vitamin C serum from
                                                    Valdivia Beauty 💛
                                                </p>
                                                <p class="management-text-en">
                                                    To lighten and unify skin
                                                    tone ✔️
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    To achieve the perfect
                                                    bronzy look and have amazing
                                                    skin during a fun summer 👌🏻
                                                </p>
                                                <p class="management-text-en">
                                                    You have to spray bronze oil
                                                    from Valdivia beauty ☀️
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    We can maintain the
                                                    appearance of our skin in
                                                    simple ways 💙
                                                </p>
                                                <p class="management-text-en">
                                                    Valdivia Beauty Anti-Blemish
                                                    Serum
                                                </p>
                                                <p class="management-text-en">
                                                    ✅ Maintain a uniform skin
                                                    tone
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/Valdivia-4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text-en">
                                                    Direct sunlight is one of
                                                    the first causes of spots
                                                    and hyperpigmentation 🌞
                                                </p>
                                                <p class="management-text-en">
                                                    Valdivia beauty sun
                                                    protection set ✋🏻
                                                </p>
                                                <p class="management-text-en">
                                                    📌 With three levels of
                                                    protection
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ////////////////////////////////////// -->
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM1.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    قربهم من بعض ومتشلش هم الكاش
                                                </p>
                                                <p class="management-text">
                                                    رجع ضحكتك المثالية من غير
                                                    مسافات في عيادة STM وركب
                                                    التقويم بالتقسيط المريح 😁
                                                </p>
                                                <p class="management-text">
                                                    متاح جميع جميع انواع التقويم
                                                    معدني وشفاف 👌
                                                </p>
                                                <p class="management-text">
                                                    اسأل علي الخصم والتقسيط وجمع
                                                    شمل سنانك المتفرقه من سنين
                                                    😌
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM2.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ابتسامتك في العيد هتبقي احلي
                                                    بجلسة تبييض في عيادة STM ✋
                                                </p>
                                                <p class="management-text">
                                                    عياده STM تتتمني لكم عيد
                                                    أضحي سعيد ❤️
                                                </p>
                                                <p class="management-text">
                                                    الحقي عروض العيد لفتره
                                                    محدوده
                                                </p>
                                                <p class="management-text">
                                                    اسألينا و هنبعتلك التفاصيل
                                                    😍
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM3.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    حاسيين بوجعك 🦷
                                                </p>
                                                <p class="management-text">
                                                    لأن ضروس العقل المدفونة مش
                                                    بس وجعها صعب
                                                </p>
                                                <p class="management-text">
                                                    ده كمان معرضه اكتر للإصابة
                                                    بالأمراض وتسوس الأسنان 🥹
                                                </p>
                                                <p class="management-text">
                                                    🆕 لفتره محدوده في عيادة STM
                                                    خلع ضرس العقل المدفون ب ٨٠٠
                                                    جنيه فقط 🆕
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="single-protfolio-box h-100">
                                            <div class="image">
                                                <a
                                                    ><img
                                                        src="assets/img/protfolio/design/STM4.jpeg"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content p-2">
                                                <p class="management-text">
                                                    ده مش كشاف نور دي اسنانك
                                                    اللي هتنور بعد اول جلسة
                                                    تبييض في عيادة STM 😁
                                                </p>
                                                <p class="management-text">
                                                    خصم مش هيتكرررر تاني 💣
                                                </p>
                                                <p class="management-text">
                                                    لو خايفه من شكل ضحتك في
                                                    الفرح أو عايزه اسنانك منوره
                                                    يبقي مفيش قدامك غير انك
                                                    تلحقي العرض🏃
                                                </p>
                                                <p class="management-text">
                                                    احجزي دلوقتي ومتخبيش ضحتك في
                                                    أي مناسبة 
                                                </p>
                                                <p class="management-text">
                                                    متنسيش تسالي علي الخصم والتقسيط   💌
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>