import { Component, OnInit } from "@angular/core";
import Odometer from "odometer";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as bootstrap from "bootstrap";
import { LanguageService } from "src/app/language.service";
declare var bootstrap: any;
interface Img {
    img: string;
}

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    isHovered = false;
    currentLanguage = 'ar';
    clients: Img[] = [
        { img: "assets/img/protfolio/logos/1.png" },
        { img: "assets/img/protfolio/logos/2.png" },
        { img: "assets/img/protfolio/logos/3.png" },
        { img: "assets/img/protfolio/logos/4.png" },
        { img: "assets/img/protfolio/logos/5.png" },
        { img: "assets/img/protfolio/logos/6.png" },
        { img: "assets/img/protfolio/logos/7.png" },
        { img: "assets/img/protfolio/logos/8.png" },
        { img: "assets/img/protfolio/logos/9.png" },
        { img: "assets/img/protfolio/logos/10.png" },
        { img: "assets/img/protfolio/logos/11.png" },
        { img: "assets/img/protfolio/logos/12.png" },
        { img: "assets/img/protfolio/logos/13.png" },
        { img: "assets/img/protfolio/logos/14.png" },
        { img: "assets/img/protfolio/logos/15.png" },
        { img: "assets/img/protfolio/logos/16.png" },
        { img: "assets/img/protfolio/logos/17.png" },
        { img: "assets/img/protfolio/logos/18.png" },
        { img: "assets/img/protfolio/logos/19.png" },
        { img: "assets/img/protfolio/logos/20.png" },
        { img: "assets/img/protfolio/logos/21.png" },
        { img: "assets/img/protfolio/logos/22.png" },
        { img: "assets/img/protfolio/logos/23.png" },
        { img: "assets/img/protfolio/logos/24.png" },
        { img: "assets/img/protfolio/logos/25.png" },
        { img: "assets/img/protfolio/logos/26.png" },
        { img: "assets/img/protfolio/logos/27.png" },
        { img: "assets/img/protfolio/logos/28.png" },
        { img: "assets/img/protfolio/logos/29.png" },
        { img: "assets/img/protfolio/logos/30.png" },
        { img: "assets/img/protfolio/logos/31.png" },
        { img: "assets/img/protfolio/logos/32.png" },
        { img: "assets/img/protfolio/logos/33.png" },
        { img: "assets/img/protfolio/logos/34.png" },
        { img: "assets/img/protfolio/logos/35.png" },
        { img: "assets/img/protfolio/logos/36.png" },
        { img: "assets/img/protfolio/logos/37.png" },
        { img: "assets/img/protfolio/logos/38.png" },
        { img: "assets/img/protfolio/logos/39.png" },
        { img: "assets/img/protfolio/logos/40.png" },
        { img: "assets/img/protfolio/logos/41.png" },
        { img: "assets/img/protfolio/logos/42.png" },
        { img: "assets/img/protfolio/logos/43.png" },
        { img: "assets/img/protfolio/logos/44.png" },
        { img: "assets/img/protfolio/logos/45.png" },
        { img: "assets/img/protfolio/logos/46.png" },
        { img: "assets/img/protfolio/logos/47.png" },
        { img: "assets/img/protfolio/logos/48.png" },
        { img: "assets/img/protfolio/logos/49.png" },
        { img: "assets/img/protfolio/logos/50.png" },
        { img: "assets/img/protfolio/logos/51.png" },
        { img: "assets/img/protfolio/logos/52.png" },
    ];
    web: Img[] = [
        { img: "assets/img/projects/1.png" },
        { img: "assets/img/projects/2.png" },
        { img: "assets/img/projects/3.webp" },
        { img: "assets/img/projects/4.webp" },
        { img: "assets/img/projects/5.webp" },
        { img: "assets/img/projects/6.webp" },
    ];
    designs: Img[] = [
        { img: "assets/img/designing/14.jpg" },
        { img: "assets/img/designing/15.jpg" },
        { img: "assets/img/designing/16.jpg" },
        { img: "assets/img/designing/17.jpg" },
        { img: "assets/img/designing/18.jpg" },
        { img: "assets/img/designing/19.jpg" },
    ];
    projectImg: Img[] = [
        { img: "assets/img/protfolio/3.webp" },
        { img: "assets/img/protfolio/5.webp" },
        { img: "assets/img/protfolio/7.webp" },
        { img: "assets/img/protfolio/9.webp" },
        { img: "assets/img/protfolio/13.webp" },
        { img: "assets/img/protfolio/14.webp" },
        { img: "assets/img/protfolio/17.webp" },
        { img: "assets/img/protfolio/20.webp" },
        { img: "assets/img/protfolio/22.webp" },
        { img: "assets/img/protfolio/23.webp" },
        { img: "assets/img/protfolio/26.webp" },
        { img: "assets/img/protfolio/27.webp" },
        { img: "assets/img/protfolio/28.webp" },
        { img: "assets/img/protfolio/29.webp" },
        { img: "assets/img/protfolio/30.webp" },
        { img: "assets/img/protfolio/31.webp" },
        { img: "assets/img/protfolio/34.webp" },
        { img: "assets/img/protfolio/35.webp" },
        { img: "assets/img/protfolio/36.webp" },
        { img: "assets/img/protfolio/37.webp" },
        { img: "assets/img/protfolio/38.webp" },
        { img: "assets/img/protfolio/40.webp" },
        { img: "assets/img/protfolio/41.webp" },
        { img: "assets/img/protfolio/42.webp" },
        { img: "assets/img/protfolio/43.webp" },
    ];
    constructor(private languageService: LanguageService) {}
    selectedImage: string = "";
    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseLeave() {
        this.isHovered = false;
    }
    openModal(image: string): void {
        this.selectedImage = image;
        const modalElement = document.getElementById(
            "imageModal"
        ) as HTMLElement;
        const modal = new bootstrap.Modal(modalElement);
        const modalDialog = modalElement.querySelector(
            ".modal-dialog"
        ) as HTMLElement | null;
        modal.show();
    }
    openModalDesigns(image: string): void {
        this.selectedImage = image;
        const modalElement = document.getElementById(
            "imageModalDesign"
        ) as HTMLElement;
        const modal = new bootstrap.Modal(modalElement);
        const modalDialog = modalElement.querySelector(
            ".modal-dialog"
        ) as HTMLElement | null;
        modal.show();
    }
    openModalWeb(image: string): void {
        this.selectedImage = image;
        const modalElement = document.getElementById(
            "imageModalWeb"
        ) as HTMLElement;
        const modal = new bootstrap.Modal(modalElement);
        const modalDialog = modalElement.querySelector(
            ".modal-dialog"
        ) as HTMLElement | null;
        modal.show();
    }
    ngOnInit(): void {
        this.languageService.currentLanguage$.subscribe(language => {
            this.currentLanguage = language;
    });
}
    customOptions: OwlOptions = {
        items: 4,
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        rtl: true,
        dots: false,
        margin: 10,
        navSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: false,
        navText: [
            `<i class='bx fs-3 bx-chevron-right'></i>`,
            `<i class='bx fs-3 bx-chevron-left'></i>`,
        ],
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 3,
            },
            740: {
                items: 4,
            },
            940: {
                items: 4,
            },
        },
        nav: true,
    };
    customOptionsLogos: OwlOptions = {
        items: 4,
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        rtl: true,
        dots: false,
        margin: 10,
        navSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        navText: [
            `<i class='bx fs-3 bx-chevron-right'></i>`,
            `<i class='bx fs-3 bx-chevron-left'></i>`,
        ],
        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 3,
            },
            740: {
                items: 4,
            },
            940: {
                items: 8,
            },
        },
        nav: true,
    };
    ngAfterViewInit(): void {
        const odometer = new Odometer({
            el: document.querySelector(".odometer"),
            value: 1234,
            format: "(d)",
            duration: 5000,
        });
        odometer.update(5678);
    }
}
