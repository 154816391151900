import { Component, OnInit } from "@angular/core";
import { LanguageService } from "src/app/language.service";

@Component({
    selector: "app-services-one",
    templateUrl: "./services-one.component.html",
    styleUrls: ["./services-one.component.scss"],
})
export class ServicesOneComponent implements OnInit {
    currentLanguage = "ar";
    constructor(private languageService: LanguageService) {}

    ngOnInit(): void {
        this.languageService.currentLanguage$.subscribe((language) => {
            this.currentLanguage = language;
        });
    }
}
