<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ "OUR_SERVICES.DESIGN_TITLE_2" | translate}}</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Great Design</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Optimal Choice</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Finest Quality</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Time Saving</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
          
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3>Digital <span>Marketing</span></h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices  Lorem Ipsum is simply dummy tex printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> SEO Marketing</li>
                        <li><i class="flaticon-check"></i> Email Marketing</li>
                        <li><i class="flaticon-check"></i> Youtube Marketing</li>
                        <li><i class="flaticon-check"></i> Social Marketing</li>
                        <li><i class="flaticon-check"></i> Facebook Marketing</li>
                        <li><i class="flaticon-check"></i> Page Ranking</li>
                    </ul>
                    <div class="digital-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/digital.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="support-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/support.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Amazing Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Big Data Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Cloud Computing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overview-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-edit"></i>
                    </div>
                    <h3>Sketch</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-thinking"></i>
                    </div>
                    <h3>Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Test</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Choose Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Page Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Location Targeting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Latest <span>Projects</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>