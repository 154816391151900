import { Component } from '@angular/core';
import { LanguageService } from 'src/app/language.service';


@Component({
  selector: 'app-web-development',
  templateUrl: './web-development.component.html',
  styleUrl: './web-development.component.scss'
})
export class WebDevelopmentComponent {
  currentLanguage = "ar";
      constructor(private languageService: LanguageService) {}
  ngOnInit(): void {
    this.languageService.currentLanguage$.subscribe((language) => {
        this.currentLanguage = language;
    });
}
}
